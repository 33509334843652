import React, {Component} from "react"
import {observer} from "mobx-react"
import GlobalStore from "../.../../../store/GlobalStore"
import {withSnackbar} from "notistack"
import {injectIntl} from "react-intl"

import "./xsSnackbar.less"

@observer
class XsSnackbar extends Component {
	constructor(props) {
		super(props)
	}

	componentDidUpdate() {
		if (isNotEmpty(GlobalStore.notificationMessage)) {
			let message = this.props.intl.formatMessage({
				id: GlobalStore.notificationMessage
			})

			this.props.enqueueSnackbar(message, {variant: GlobalStore.notificationVariant})

			GlobalStore.notificationMessage = ""
			GlobalStore.notificationVariant = "default"
			GlobalStore.notificationData = ""
		}
	}

	render() {
		GlobalStore.notificationMessage
		return <span data-snackbar={GlobalStore.notificationData} />
	}
}

export default withSnackbar(injectIntl(XsSnackbar))
