"use strict"

import {observable, action, reaction} from "mobx"
import DataStore from "../../modules/ambulance/stores/DataStore"
// import CalendarStore from "../../modules/ambulance/stores/CalendarStore"
import {codeListsConfig} from "../config/codeLists"
import api from "../config/api"
import config from "../config/settings"
// import moment from "moment"

import {getUser, getSelectedOrgUnit, getUserPersonnelID} from "../helpers/actions"
import {ixsIsUndefined, ixsIsNull} from "../helpers/functions"
import {StorageBase} from "../storage/storageEx"
import {USER_SETTINGS} from "../config/constants"
import actions from "../../modules/actions/api"

// configure({ isolateGlobalState: true })

class GlobalStore {
	constructor() {
		reaction(
			() => this.forms,
			() => {
				//if (this.forms % 7 === 0)
				this.force = Date.now()
			}
		)
	}
	@observable CL = {}

	@observable isAuthenticated = false
	@observable authentificationFailed = false
	@observable authentificationInProgress = false

	@observable GWAppRunningState = 0 // 0 - not checked yet, 1 - success, 2 - failure, 3 - checking
	@observable ZprRegisteredInEhealthState = 0 // 0 - not checked yet, 1 - success, 2 - failure, 3 - checking
	@observable PrZSRegisteredInEhealthState = 0 // 0 - not checked yet, 1 - success, 2 - failure, 3 - checking

	EHGWErrorStack = []

	@observable force = Date.now()

	@observable forms = 0

	@observable titleCL = []
	@observable genderCL = []

	isUpdatingGW = false
	updatingTimeout = null
	updatingTimeoutPassed = false
	isEHGWOutdated = false

	registerZprTimeout = null
	registerZprTimeoutPassed = false

	@action loadCL() {
		this.loadTitle()
		this.loadGender()
	}

	@action loadTitle() {
		actions
			.loadTitles()
			.call()
			.then((titles) => {
				if (isSafe(titles) && isSafe(titles.rows) && titles.rows.length) {
					let tempTitles = []
					titles.rows.forEach((title) => {
						tempTitles.push({
							_id: title._id,
							code: title.code,
							code_ext: title.code_ext,
							name_ext: title.code_ext,
							name: title.code_ext
						})
					})
					this.titleCL = tempTitles
				} else {
					this.titleCL = []
				}
			})
	}

	@action loadGender() {
		actions
			.loadGenders()
			.call()
			.then((genders) => {
				if (isSafe(genders) && isSafe(genders.rows) && genders.rows.length) {
					this.genderCL = genders.rows
				} else {
					this.genderCL = []
				}
			})
	}

	@action registerDoctor(form) {
		let values = form.values()

		const req = {
			_Ref: false,
			_Type: "EHR.API.JSON.Proxy.Personnel.Registration",
			CompanyIdentifier: values.company_identifier,
			CompanyName: values.company_name,
			Doctorcode: values.doctor_code,
			DzpLogin: values.dzp_login,
			DzpPassword: values.dzp_password,
			FirstName: values.first_name,
			GenderId: isNotEmpty(values.gender) ? values.gender : null,
			LastName: values.last_name,
			ProviderCode: values.provider_code,
			TitleAfterId: isNotEmpty(values.title_after) ? values.title_after : null,
			TitleBeforeId: isNotEmpty(values.title_before) ? values.title_before : null,
			UnionLogin: values.union_login,
			UnionPassword: values.union_password,
			VszpLogin: values.vszp_login,
			VszpPassword: values.vszp_password
		}

		actions
			.registerDoctor(req)
			.call()
			.then((res) => {
				logger("res", res)
				this.setNotificationMessage("Registrácia prebehla úspešne")
			})
	}

	@action InitEhealthStates() {
		this.GWAppRunningState = 0
		this.ZprRegisteredInEhealthState = 0
		this.PrZSRegisteredInEhealthState = 0
	}

	@action increaseForms() {
		this.forms = this.forms + 1
	}

	@observable orgunits = []
	@observable orgunitsId = undefined
	@observable orgunitsResourceId = undefined
	@observable orgunitExpertiseCodeExt = undefined
	@observable currentOrgName = ""
	@observable userName = ""
	@observable refresh = Date.now()
	@observable confirmationDialogOpened = undefined

	allowOrgUnitExpertiesCodeExt = ["008", "009", "020", "007", "022"]

	@observable routes = null
	confirmationDialogParams = {}

	openingHour = {}

	@observable notificationMessage = ""
	@observable notificationData = ""
	@observable notificationVariant = "default"

	@observable gwApp = observable.map({
		Me: "red",
		Zpr: "grey",
		PrZS: "grey",
		Actions: []
	})

	BLConfiguration = undefined
	BLConfigurationLoading = false
	@observable BLConfigurationLoaded = false

	defaultFocusFields = {}
	defaultPrescriptionFields = {}

	@action handleClickFocus(formName, isTextArea = false) {
		if (isTextArea) {
			if (isSafe(this.defaultFocusFields[formName]) && isSafe(this.defaultFocusFields[formName].reactQuillRef)) {
				this.defaultFocusFields[formName].reactQuillRef.focus()
			}
		} else {
			if (isSafe(this.defaultFocusFields[formName])) {
				this.defaultFocusFields[formName].focus()
			}
		}
	}

	@action handleClickPrescriptionFocus(key, isTextArea = false) {
		if (key) {
			if (isTextArea) {
				if (isSafe(this.defaultPrescriptionFields[key]) && isSafe(this.defaultPrescriptionFields[key].reactQuillRef)) {
					this.defaultPrescriptionFields[key.toString()].reactQuillRef.focus()
				}
			} else {
				if (isSafe(this.defaultPrescriptionFields[key])) {
					this.defaultPrescriptionFields[key.toString()].focus()
				}
			}
		}
	}

	@action Autenticate() {
		this.authentificationFailed = false
		this.authentificationInProgress = false
		this.isAuthenticated = true

		//Ciselniky sa loaduju v RegistersCacheStore
		// this.loadCodeLists()
		// DataStore.loadInsurers()

		this.setUserData()
		// this.GetBLConfiguration()

		// if (config.GW_BASE_URL) {
		// 	this.GWLogin()
		// } else {
		// 	RouterStore.push(settings.HOMEPAGE)
		// }
		// this.RegisterZpr()

		// MAROS: toto som zakomentoval a pridal to do then a catch v this.RegisterZpr()
		// RouterStore.push(settings.HOMEPAGE)
	}

	@action GetBLConfiguration() {
		if (isNotSafe(this.BLConfiguration)) {
			api.loadTypeRoutes().then((c) => ((this.BLConfiguration = c), (this.BLConfigurationLoaded = true)))
		}
	}
	@action setUserData() {
		const cookies = getUser()

		if (isSafe(cookies) && ixsIsNull(getSelectedOrgUnit())) {
			//const userSettings = Storage.getItem(USER_SETTINGS)
			const userSettings = StorageBase.getObjectByKey(USER_SETTINGS)
			const defaultOrgUnit = cookies.orgunits.find((o) => o.primary)

			let selectedOrgUnit = null
			if (userSettings && userSettings[cookies.user._id]) {
				selectedOrgUnit = userSettings[cookies.user._id].selectedOrgUnit
			} else {
				selectedOrgUnit = defaultOrgUnit ? defaultOrgUnit : cookies.orgunits[0]
			}

			this.orgunits = cookies.orgunits
			this.orgunitsId = selectedOrgUnit._id
			this.orgunitsResourceId = selectedOrgUnit.resource_id
			this.orgunitExpertiseCodeExt = defaultOrgUnit
				? defaultOrgUnit.expertises[0].expertise.code_ext
				: cookies.orgunits[0].expertises[0].expertise.code_ext

			this.setOpenningHour(selectedOrgUnit)

			this.currentOrgName = selectedOrgUnit.name
			this.userName = isSafe(cookies) && isSafe(cookies.person) ? cookies.person.full_name : ""
		}
	}

	//Zmena oddelenia cez hlavičku - xsHeader

	@action setOpenningHour(orgunit) {
		if (isSafe(orgunit.opening_hours)) {
			let tempOpenningHour = {}

			orgunit.opening_hours.forEach((day) => {
				if (isSafe(day.opened)) {
					if (day.opened.length === 1) {
						let amFrom = +day.opened[0].from.substring(0, 2)
						let pmTo = +day.opened[0].to.substring(0, 2)

						tempOpenningHour[day.day] = {from: amFrom, to: pmTo}
					}

					if (day.opened.length === 2) {
						let amFrom = +day.opened[0].from.substring(0, 2)
						let pmTo = +day.opened[1].to.substring(0, 2)

						tempOpenningHour[day.day] = {from: amFrom, to: pmTo}
					}
				}
			})

			this.openingHour = tempOpenningHour
		}
	}

	@action changeOrgUnit(selectedOrgUnit) {
		this.currentOrgName = selectedOrgUnit.name
		this.orgunitsId = selectedOrgUnit._id
		this.orgunitsResourceId = selectedOrgUnit.resource_id
		this.orgunitExpertiseCodeExt = selectedOrgUnit.expertises[0].expertise.code_ext

		this.setOpenningHour(selectedOrgUnit)

		if (getUser() && getUser().user._id) {
			let userSettings = {}
			userSettings[getUser().user._id] = {
				selectedOrgUnit: {
					_id: selectedOrgUnit._id,
					name: selectedOrgUnit.name,
					resource_id: selectedOrgUnit.resource_id,
					opening_hours: selectedOrgUnit.opening_hours
				}
			}

			//Storage.setItem(USER_SETTINGS, userSettings)
			StorageBase.updateByKey(USER_SETTINGS, userSettings)
		}
		DataStore.onSelectedOrgUnitChange()
		DataStore.setServices()
		this.RegisterZpr()
	}

	async logoutGWApp() {
		const user = getUser()
		const selectedOrgUnit = getSelectedOrgUnit()
		const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
		const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
		const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
		const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
		const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

		try {
			await fetch(`${config.GW_BASE_URL}/Logout`, {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
					AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
					AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
					AmbeePersonnelId: getUserPersonnelID(),
					OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
					PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
					PersonnelExpertiseJRUZVersion: specVer,
					PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
				})
			})
		} catch (err) {
			logger(err)
		}
		// .then(() => {})
		// .catch((err) => {
		// 	logger("EHGW logout err:", err)
		// })
	}

	@action openConfirmationDialog(dialogName, params) {
		this.confirmationDialogParams = params
		this.confirmationDialogOpened = dialogName
	}

	@action closeConfirmationDialog() {
		this.confirmationDialogParams = {}
		this.confirmationDialogOpened = undefined
	}

	@action refreshCodeLists(codeListNames, onSuccessLoad) {
		codeListNames.forEach((codeListName) => {
			const codeListParams = codeListsConfig.filter((x) => x.name === codeListName)[0]
			if (ixsIsUndefined(this.CL[codeListParams.name])) {
				api.loadCodeList(codeListParams.uri).then((val) => {
					if (
						codeListName == "allergens" ||
						codeListName == "symptoms" ||
						codeListName == "vaccinationSymptoms" ||
						codeListName == "vaccinationTypes" ||
						codeListName == "patientLocations"
					) {
						this.CL[codeListParams.name] = val.rows.sort((a, b) =>
							(isSafe(a.name_ext) ? a.name_ext : "").localeCompare(isSafe(b.name_ext) ? b.name_ext : "")
						)
					} else {
						this.CL[codeListParams.name] = val.rows
					}
					if (onSuccessLoad) {
						onSuccessLoad()
					}
				})
			} else {
				if (onSuccessLoad) {
					onSuccessLoad()
				}
			}
		})
	}

	@action async refreshCodeListsSync(codeListNames) {
		const mapCL = []
		const promises = codeListNames
			.map((codeListName) => {
				const codeListParams = codeListsConfig.find((x) => x.name === codeListName)
				if (isNotSafe(this.CL[codeListParams.name])) {
					mapCL.push({codeListName, codeListParams})
					return api.loadCodeList(codeListParams.uri)
				} else {
					return null
				}
			})
			.filter(isSafe)

		const results = await Promise.all(promises)

		results.forEach((val, idx) => {
			const {codeListName, codeListParams} = mapCL[idx]
			if (
				codeListName == "allergens" ||
				codeListName == "symptoms" ||
				codeListName == "vaccinationSymptoms" ||
				codeListName == "vaccinationTypes" ||
				codeListName == "patientLocations"
			) {
				this.CL[codeListParams.name] = val.rows.sort((a, b) =>
					(isSafe(a.name_ext) ? a.name_ext : "").localeCompare(isSafe(b.name_ext) ? b.name_ext : "")
				)
			} else {
				this.CL[codeListParams.name] = val.rows
			}
		})
	}

	@action loadCodeLists() {
		// codeListsConfig.map((cl) => {
		// 	api.loadCodeList(cl.uri).then((val) => {
		// 		if (cl.name == "allergens") {
		// 			this.CL[cl.name] = val.rows.sort((a, b) =>
		// 				(isSafe(a.name_ext) ? a.name_ext : "").localeCompare(isSafe(b.name_ext) ? b.name_ext : "")
		// 			)
		// 		} else {
		// 			this.CL[cl.name] = val.rows
		// 		}
		// 	})
		// })
	}

	@action setNotificationMessage(message, data = "", variant = "default") {
		if (isNotEmpty(message)) {
			this.notificationVariant = variant
			this.notificationMessage = message
			this.notificationData = data
		}
		// this.notificationMessage = ""
	}
}

var singleton = new GlobalStore()
export default singleton
